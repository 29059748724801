import React from "react";
import { Box, useTheme } from "@mui/material";
import airAwareLogo from "/@/assets/air-aware-logo.svg";

const AuthHomeLink = () => {
  const theme = useTheme();
  // TODO: Fix this with link back to home page
  return (
    <Box
      sx={{
        position: "absolute",
        top: 20,
        left: 0,
        bgcolor: theme.palette.primary.main,
        width: 150,
        borderRadius: "0 10px 10px 0",
        p: 2,
      }}
      textAlign={"center"}
    >
      <img src={airAwareLogo} />
    </Box>
  );
};

export default AuthHomeLink;
